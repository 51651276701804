import axios from 'axios';
import useToken from '../hooks/useToken';
import React, { useEffect, useState } from 'react';
import { getApiBaseUrl } from '../utils/HelperUtils';

const api = axios.create();

export type InterceptorProps = {
  children?: React.ReactNode;
};

const AxiosInterceptor = (props: InterceptorProps) => {
  const auth = useToken();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    api.interceptors.request.use((config) => {
      config.baseURL = getApiBaseUrl();
      const userData = localStorage.getItem('token');
      if (userData) {
        const token = JSON.parse(userData);
        config.headers['Authorization'] = 'Bearer ' + token.token;
        if (token.as) {
          config.headers['Execute-As'] = token.as;
        }
      }
      return config;
    });

    api.interceptors.response.use(
      (config) => {
        return config;
      },
      (error) => {
        if ((error.response?.status === 403 || error.status === 403) || (error.response?.status === 401 || error.status === 401) ) {
          auth.removeToken();
          window.location.href = '/login';
        }
        return Promise.reject(error)
      },
    );
    setIsSet(true);
  }, []);

  // need to check use effect has run as it can be async
  if (!isSet) {
    return <></>;
  }

  return <>{props.children}</>;
};

export default api;

export { AxiosInterceptor };
