import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NonCustodyHolding } from '../../dto/Holding';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import useWindowSize from '../../hooks/useWindowSize';
import SidePanel from '../../components/Investments/SidePanel';
import { renderSideContent } from '../../components/Investments/SidePanelContent';
import ContentComponent from '../../components/ContentContainer/Content';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import TableSort from '../../components/Investments/InvestmentTableSort';
import TrendingOffersBox from '../../components/PageSection/TrendingOffersBox';
import PaginationButtons from '../../components/Pagination/Pagination';
import DrilldownTable from '../../components/Investments/DrilldownTable';
import DrilldownValue from '../../components/Investments/DrilldownValue';
import MobileInvestmentBlock from './MobileInvestmentBlock';
import ErrorBanner from '../../components/Error/ErrorBanner';
import WarningMessage from '../../components/Warning/WarningMessage';
import { DEFAULT_WARN_MSG } from '../../utils/Constants';
import AimIhtWarning from '../../components/Warning/AimIhtWarning';

type SortValues = 'investment' | 'value' | 'growth' | null;

export type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

const InvestmentDrillDown = () => {
  const { parent, child, wrapper, accountId, cat } = useParams();
  const [pager, setPager] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [investments, setInvestments] = useState<Array<NonCustodyHolding>>([]);
  const [filteredInvestments, setFilteredInvestments] = useState<Array<NonCustodyHolding>>([])
  const [cashValue, setCashValue] = useState(0)
  const [sideBar, setSideBar] = useState<SideBarContent>(null);
  const [desc, setDesc] = useState<boolean>(true);
  const [sort, setSort] = useState<SortValues>('investment');
  const { width } = useWindowSize();
  const [childrenInvestments, setChildrenInvestments] = useState<Array<NonCustodyHolding>>([]);

  const { investmentService } = useDependencyInjector();

  const updatePage = (page: number) => {
    setPager(page);
  };

  // Effect to handle cash value and filtering
  useEffect(() => {
    if (investments.length > 0) {
      setCashValue(investmentService.getCashValue(investments));
      const nonCashInvestments = investmentService.removeCashHolding(investments);
      setFilteredInvestments(nonCashInvestments);
    }
  }, [investments, investmentService]);

  const closeSideBar = () => {
    setSideBar(null);
  };

  const sortingFunc = (a: any, b: any) => {
    if (a > b) {
      return desc ? 1 : -1;
    }
    if (a < b) {
      return desc ? -1 : 1;
    }
    return 0;
  };

  const alphaSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
    return sortingFunc(
      a.investment.name.toLowerCase(),
      b.investment.name.toLowerCase(),
    );
  };

  const growthSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
    return sortingFunc(a.growth, b.growth);
  };

  const valueSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
    return sortingFunc(a.value, b.value);
  };

  const { isLoading, isError, data } = useQuery({
    queryKey: ['holdings', parent, child, wrapper, accountId],
    queryFn: async () => {
      if (wrapper && accountId) {
        return await investmentService.getNonCustodyHoldingsDfm(accountId);
      }
      return await investmentService.getNonCustodyHoldings(parent!, child!);
    },
    enabled: (parent != undefined && child != undefined) || (wrapper != undefined && accountId != undefined),
  });

  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  const getSubtitleText = () => {
    if (child) {
      return child.replaceAll('_', ' ');
    }
    if (wrapper) {
      return wrapper.replaceAll('_', ' ');
    }
    return '';
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const sortedData = investmentService.getTopLevelInvestments(data);
    const children = data.filter(value => value.parentHolding != undefined && (value.invDate != null ||  value.value > 0));
    setChildrenInvestments(children);

    if (sortedData && sortedData.length > 0) {
      switch (sort) {
        case 'investment':
          sortedData.sort(alphaSort);
          break;
        case 'value':
          sortedData.sort(valueSort);
          break;
        case 'growth':
          sortedData.sort(growthSort);
          break;
      }

      const numberOfInvestments = sortedData.length;
      const MAX_PAGE_SIZE = 15;

      let numberOfPagesRequired = Math.floor(numberOfInvestments / MAX_PAGE_SIZE);
      if (numberOfInvestments % MAX_PAGE_SIZE > 0) {
        numberOfPagesRequired += 1;
      }
      setNumberOfPages(numberOfPagesRequired);
      if (numberOfPagesRequired === 1) {
        setInvestments(sortedData);
      } else if (pager === numberOfPagesRequired) {
        const invests = sortedData.slice(pager * MAX_PAGE_SIZE - MAX_PAGE_SIZE, sortedData.length);
        setInvestments(invests);
      } else {
        const invests = sortedData.slice(pager * MAX_PAGE_SIZE - MAX_PAGE_SIZE, pager * MAX_PAGE_SIZE);
        setInvestments(invests);
      }
    }
  }, [pager, desc, sort, data]);

  if (isLoading) {
    return (
      <ContentComponent title={window.innerWidth > 700 ? 'My portfolio' : getTitleText()} subtitle={getSubtitleText()} secondaryTitle={window.innerWidth > 700 ? getTitleText() : ''}>
        <LoadingBlock />
        <WarningMessage message={child !== "AIM_IHT_portfolios" ? DEFAULT_WARN_MSG : <AimIhtWarning />} />
        <TrendingOffersBox />
      </ContentComponent>
    );
  }

  if (isError && parent) {
    return (
      <ContentComponent title={window.innerWidth > 700 ? 'My portfolio' : getTitleText()} subtitle={getSubtitleText()} secondaryTitle={window.innerWidth > 700 ? getTitleText() : ''}>
        <ErrorBanner text={'An error occurred while loading holdings. Please try again.'} severe={true} />
        <WarningMessage message={child !== "AIM_IHT_portfolios" ? DEFAULT_WARN_MSG : <AimIhtWarning />} />
        <TrendingOffersBox />
      </ContentComponent>
    );
  }

  return (
    <div>
      <SidePanel open={sideBar != null} close={closeSideBar}>
        {renderSideContent(sideBar, data)}
      </SidePanel>
      <ContentComponent title={window.innerWidth > 700 ? 'My portfolio' : parent || wrapper ? getTitleText() : ''} subtitle={getSubtitleText()} secondaryTitle={window.innerWidth > 700 ? getTitleText() : ''}>
        <div className="w-[100%] h-fit mb-12">
          {data && !isLoading && (
            <>
              {width > 1200 && (
                <>
                  {child === "AIM_IHT_portfolios" && <WarningMessage message={<AimIhtWarning />} />}
                  <DrilldownValue totalValue={investmentService.totalUpHoldings(data)} isDfm={accountId != null || accountId != undefined} setSideBar={setSideBar} cashValue={cashValue} />
                  <TableSort setSort={setSort} sort={sort} setDesc={setDesc} desc={desc} />
                  <DrilldownTable nonCustodyHoldings={filteredInvestments} childrenCustodyHoldings={childrenInvestments} setSideBar={setSideBar} isDfm={accountId != null || accountId != undefined} />
                </>
              )}

              {width < 1200 && (
                <>
                  {child === "AIM_IHT_portfolios" && <WarningMessage message={<AimIhtWarning />} />}
                  <DrilldownValue totalValue={investmentService.totalUpHoldings(data)} isDfm={accountId != null || accountId != undefined} setSideBar={setSideBar} cashValue={cashValue} />
                  {filteredInvestments.map((value, index) => (
                    <MobileInvestmentBlock holding={value} setSideBar={setSideBar} key={index} childrenInvestments={childrenInvestments} isDfm={accountId != null || accountId != undefined} />
                  ))}
                </>
              )}
              <div className="w-full">
                {numberOfPages > 1 && investments && investments.length > 0 && <PaginationButtons pageCount={numberOfPages} onPageChange={updatePage} />}
              </div>
            </>
          )}
          {child !== "AIM_IHT_portfolios" && <WarningMessage message={DEFAULT_WARN_MSG} />}
          <TrendingOffersBox />
        </div>
      </ContentComponent>
    </div>
  );
};

export default InvestmentDrillDown;