import React, { useEffect, useRef, useState } from 'react';
import { SecureMessage } from '../../dto/SecureMessage';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { useQuery } from '@tanstack/react-query';
import { AttachmentLoader } from './AttachmentLoader';
import ViewTopBar from './ViewTopBar';
import AttachmentBox from './AttachmentsBox';
import ErrorBanner from '../Error/ErrorBanner';
import ErrorToast from '../Toast/ErrorToast';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ReplyIcon } from '../../images/icons/icon-reply.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/icon-delete.svg';
import { IToast } from '../Toast/Toast';

interface ViewMessageProps {
  setSidebar(sidebarOpen: boolean): void;

  sidebar: boolean;
  secureMessage: SecureMessage | undefined;
  updateCurrentSecureMessage: (secureMessages: SecureMessage) => void;
  hasAttachment: number;
  deleteCurrentMessage: (secureMessageId: string) => void;
  refetch: () => void;
  folder: string;
}



function ViewMessage(props: ViewMessageProps) {
  const mainDivRef = useRef<HTMLDivElement | null>(null);
  const nav = useNavigate();

  const [errorToast, setErrorToast] = useState<IToast | null>(null);
  const { secureMessageService } = useDependencyInjector();


  useEffect(() => {
    if (errorToast?.show) {
      const timeOut = setTimeout(() => setErrorToast(null), 3000);
      return () => clearTimeout(timeOut);
    }
  }, [errorToast]);

  const {
    isLoading: loadingAttachments,
    isError: errorAttachments,
    data: attachments,
  } = useQuery({
    queryKey: ['attachments', props.secureMessage?.id],
    queryFn: async () => {
      return await secureMessageService.getSecureMessageAttachments(
        props.secureMessage!.id,
      );
    },
    enabled:
      props.secureMessage &&
      props.secureMessage.id != undefined &&
      props.secureMessage?.attachmentCount > 0,
  });


  const markMessageAsRead = async (messageId: string) => {
    await secureMessageService.setMessageAsRead(messageId);
    props.refetch();
  };

  useEffect(() => {
    if (props.secureMessage) {
      if (!props.secureMessage.read) {
        markMessageAsRead(props.secureMessage.id).catch((reason) => {
          setErrorToast({ show: true, message: 'Error setting secure message to read.' });
        }).finally(() => {
          props.updateCurrentSecureMessage({ ...props.secureMessage, read: true } as SecureMessage);
        });
      }

      document.body.style.overflow = 'hidden';
    }
    if (mainDivRef.current) {
      mainDivRef.current!.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [props.secureMessage]);

  const displayFromOrTo = (fromClient: boolean) => {
    if (fromClient) {
      return 'To: WealthClub';
    }
    return 'From: WealthClub';
  };


  return (
    <div
      className={`${
        props.sidebar ? '' : 'hidden'
      } absolute flex flex-row z-[99999999] fade-in `}
      style={{ height: document.documentElement.offsetHeight }}>


      <div
        className="xl:w-[60vw] hidden lg:block bg-tuxedo opacity-40 h-auto min-h-screen overflow-hidden"
        onClick={() => props.setSidebar(false)}></div>


      <ErrorToast message={errorToast?.message ? errorToast.message : ''}
                  show={errorToast?.show == null ? false : errorToast.show} closeFn={() => {
        setErrorToast(null);
      }} />

      <div className="overflow-auto h-screen" ref={mainDivRef}>
        <div className="xl:w-[40vw] w-[100vw] h-auto min-h-full bg-white">
          <div className={`w-[92%] ml-[3%] flex flex-col h-auto pb-2`}>
            <div>
              <ViewTopBar
                deleteCurrentMessage={props.deleteCurrentMessage}
                secureMessage={props.secureMessage}
                setSidebar={props.setSidebar}
                folder={props.folder}
              />
            </div>

            <div className="mt-4 fade-in">
              <div className="mt-4 flex flex-col">
                <h5 className="title text-base text-bond">
                  {props.secureMessage
                    ? displayFromOrTo(props.secureMessage.fromClient)
                    : ''}{' '}
                </h5>
                <h2 className="pt-1 pb-1">
                  RE: {props.secureMessage ? props.secureMessage.subject : ''}{' '}
                </h2>
                <h4 className="text-sm">
                  {props.secureMessage
                    ? props.secureMessage.sentTs
                    : ''}
                </h4>
              </div>
              <hr className="border-1 border-vistaWhite w-full mt-4 mb-4" />

              {props.hasAttachment != 0 && loadingAttachments && (
                <AttachmentLoader />
              )}

              {errorAttachments && !loadingAttachments && (
                <div className="mt-2">
                  <ErrorBanner
                    severe={true}
                    text="An error occurred while loading your attachments, please try again later"
                  />
                </div>
              )}

              {attachments && attachments.length > 0 && !errorAttachments && (
                <AttachmentBox attachments={attachments} />
              )}

              <div className="mt-8 h-auto pb-4 pt-4 fade-in">
                {props.secureMessage ? (
                  <div

                    className={"danger-html"}
                    dangerouslySetInnerHTML={{
                      __html: props.secureMessage.body,
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            <hr className="border-1 border-vistaWhite w-full mt-4 mb-4" />

            {props.secureMessage && (
              <div className="w-[90%] flex flex-row gap-4">
                <div
                  className={`flex flex-row items-center cursor-pointer ${
                    props.folder == 'inbox' ?  'visible' :'hidden'
                  }`}
                  onClick={() => {
                    props.setSidebar(false);
                    nav('/reply/' + props.secureMessage!.id);
                  }}>
                  <ReplyIcon className="h-[18px] fill-bond"></ReplyIcon>
                  <div className="text-bond text-sm font-normal underline">Reply</div>
                </div>
                <div
                  className={`flex flex-row items-center cursor-pointer ${
                    props.folder == 'deleted' ? 'hidden' : ''
                  }`}
                  onClick={event => {
                    if (props.secureMessage) {
                      props.deleteCurrentMessage(props.secureMessage.id);
                    }
                  }}>
                  <DeleteIcon className="h-[18px] cursor-pointer fill-bond"></DeleteIcon>
                  <div className="text-bond text-sm font-normal underline">Delete</div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewMessage;
