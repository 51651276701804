import { useState } from 'react';

export interface UserToken {
  user: User;
  roles: Array<string>;
  group: string;
  token: string;
  as?: string;
  asName?: string;
}

export interface User {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  username: string;
}

export interface Token {
  removeToken: () => void;
  setToken: (userToken: UserToken) => void;
  token: UserToken;
}

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    if (!tokenString) {
      return null;
    }
    return JSON.parse(tokenString);

  };

  const [token, setToken] = useState<UserToken | null>(getToken());

  const removeToken = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const saveToken = (userToken: UserToken) => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    removeToken: removeToken,
    setToken: saveToken,
    token,
  } as Token;
}
