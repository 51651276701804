import React, { useState } from 'react';
import { ReactComponent as EyeIcon } from '../../images/icons/eye-icon.svg';
import { ReactComponent as EyeClosedIcon } from '../../images/icons/eye-closed-icon.svg';

interface LoginProps {
  type: string;
  name: string;
  value: string | undefined | number;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  error?: boolean;
  id?: string;
  ref?: React.Ref<HTMLInputElement>;
  placeholder?: string;
  className?: React.ComponentProps<'input'>['className'];
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  minLength?: number;
}

function PasswordInput(props: LoginProps) {
  const [showPassword, setShowPassword] = useState(false);

  const renderIcon = () => {
    if (showPassword) {
      return <EyeClosedIcon className={"h-8 w-8 text-midas"}/>;
    }
    return <EyeIcon className={"h-8 w-8 text-midas"}/>;
  };

  return (
    <div className="relative">
      <input
        disabled={props.disabled}
        data-testid={'login-input'}
        type={showPassword ? "text":props.type}
        name={props.name}
        id={props.id}
        maxLength={props.maxLength}
        minLength={props.minLength}
        ref={props.ref}
        value={props.value}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className={`${
          props.className
        } h-[40px] p-3 border-2 focus:outline-midas focus:bg-sand focus:shadow-2xl transition-all duration-500 w-full ${
          props.error ? 'border-[#ff0000]' : 'border-midasLight'
        }`}
      />
      <div
        className={'absolute inset-y-0 right-0 flex items-center pr-3'}
        onClick={() => {
          setShowPassword((prevState) => !prevState);
        }}>
        {renderIcon()}
      </div>
    </div>
  );
}

export default PasswordInput;