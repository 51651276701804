import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDependencyInjector } from '../../context/DependencyInjector';
import useToken from '../../hooks/useToken';
import ErrorMessage from '../../errors/ErrorMessage';
import { Button, Input } from 'private-wc-ui';
import useRedirect from '../../hooks/useRedirect';
import PasswordInput from '../Inputs/PasswordInput';

interface FormValues {
  username: string;
  password: string;
}

interface Errors {
  username?: string;
  password?: string;
}

interface LoginProps {
  setPasswordReset: (value: React.SetStateAction<boolean>) => void;
  forceUserName: string | null | undefined;
  loginParaText: string | null | undefined;
  loginCaptionText: string | null | undefined;
}

function LoginForm(props: LoginProps) {
  const initialValues: FormValues = { username: props.forceUserName ? props.forceUserName : '', password: '' };
  const navigate = useNavigate();
  const { authService } = useDependencyInjector();
  const { setToken } = useToken();
  const loginParaText = props.loginParaText;
  const loginCaptionText = props.loginCaptionText;

  const redirect = useRedirect();

  const formik = useFormik({
    initialValues: initialValues,
    validate: (values) => {
      const errors: Errors = {};
      if (values.username.length == 0) {
        errors.username = 'Please enter a valid username.';
      }
      if (values.password.length == 0) {
        errors.password = 'Please enter a valid password.';
      }
      return errors;
    },
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const handleLogin = (formValues: FormValues) => {
    authService
      .login(formValues.username, formValues.password)
      .then((user) => {
        setToken(user);
        if (redirect.redirect) {
          const url = redirect.redirect;
          redirect.removeRedirect();
          parent.window.location.href = url + '?id=' + user.token;
          return;
        }
        if (user.group == 'Company') {
          scrollTo(0, 0);
          navigate('/Search');
          return;
        }
        scrollTo(0, 0);
        navigate('/dashboard');
      })
      .catch((reason) => {
        if (reason != null) {
          const errorMsg = reason as ErrorMessage;
          formik.setFieldError('password', errorMsg.error);
        } else {
          formik.setFieldError(
            'password',
            'An unknown error occured, please try again later.',
          );
        }
      });
  };

  return (
    <div className="w-full h-full fade-in">
      <h2>{loginCaptionText}</h2>
      <hr className="border-1 border-midas mt-3"></hr>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col bg-white md:p-3 pt-3 pb-3 gap-3 text-left w-full">
        <p className={"text-sm"}>{(loginParaText)}</p>

        <label
          className="font-sans font-bold text-tuxedo text-[14px] -mb-2"
          htmlFor="username">
          Username
        </label>
        <Input
          type={'text'}
          name={'username'}
          value={formik.values.username}
          disabled={props.forceUserName != null}
          onChange={formik.handleChange}
          error={
            formik.errors.username != undefined &&
            formik.errors.username.length != 0 &&
            formik.touched.username
          }
          id={'username'} />
        {formik.errors.username && formik.touched.username ? (
          <div className="fade-in" style={{ color: 'red' }}>
            {formik.errors.username}
          </div>
        ) : null}

        <label
          className="font-sans font-bold text-tuxedo p-0 text-[14px] -mb-2"
          htmlFor="password">
          Password
        </label>
        <PasswordInput
          type={'password'}
          name={'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={
            formik.errors.password != undefined &&
            formik.errors.password.length != 0 &&
            formik.touched.password
          }
          id={'password'}
        />
        {formik.errors.password && formik.touched.password ? (
          <div className="fade-in" style={{ color: 'red' }}>
            {formik.errors.password}
          </div>
        ) : null}
        <div className="mt-2 mb-2 flex flex-col md:flex-row w-full">
          <div className="w-full mb-2 md:w-[30%]">
            <Button label={'Log in'} theme={'primary'} type={'submit'} style={{ width: '100%' }} />
          </div>
          <a
            onClick={() => props.setPasswordReset(true)}
            href="#"
            className="text-sm text-bond text-center md:text-right underline md:ml-[31%]">
            Forgotten username or password?
          </a>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;