import React from 'react';

interface MessageProps {
  title: string;
  subject: string;
  date: string;
  message: string;
}

function MessagePreview(props: MessageProps) {
  return (
    <div className="w-full h-auto min-h-[10vh] bg-downy flex flex-row mt-10 shadow-xl">
      <div className="flex flex-col p-6 text-left bg-white w-[calc(100%-10px)] h-full ml-[10px]">
        <h6 className="title text-sm text-bond">{props.title}</h6>
        <h4>{props.subject}</h4>
        <h6 className="text-sm font-bold mt-2 text-tuxedo mb-6">
          {props.date}
        </h6>
        <div className={"danger-html"} dangerouslySetInnerHTML={{ __html: props.message }}></div>
      </div>
    </div>
  );
}

export default MessagePreview;
