import React, { useEffect, useState } from 'react';
import logo from '../../images/wealth-club-portal-logo.svg';
import ReturnLink from '../ReturnLink/Return';
import { ReactComponent as DashboardLogo } from '../../images/icons/icon-dashboard.svg';
import { ReactComponent as PortfolioLogo } from '../../images/icons/icon-portfolio.svg';
import { ReactComponent as SecMesLogo } from '../../images/icons/icon-secure-messages.svg';
import { ReactComponent as IconGear } from '../../images/icons/icon-gear.svg';
import { ReactComponent as DropdownIcon } from '../../images/icons/icon-chevron-down.svg';
import { Link, useNavigate } from 'react-router-dom';
import AccountViewSwitch from '../AccountView/AccountViewSwitch';
import LogoutComponent from '../Logout/Logout';
import { useDropDownContext } from '../../context/DropDownContext';
import LoadingBlock from '../Loading/LoadingBlock';
import NavDropDownLinks from '../NavItem/NavDropDownLinks';
import { NavOpenObject } from '../../pages/Nav';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import useToken from '../../hooks/useToken';

export interface ParentProps {
  parent: string;
}

export type DropDownLinks = {
  href: string;
  hrefText: string;
};

function MobileMenu(props: ParentProps) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { secureMessageService, investmentService } = useDependencyInjector();

  const drop = useDropDownContext();

  const [open, setOpen] = useState<NavOpenObject>({ accountSettings: false, portfolio: false });

  const nav = useNavigate();

  const auth = useToken()

  const { isLoading: navIsLoading, data: navData } = useQuery({
    queryKey: ['nav-data'],
    staleTime: Infinity,
    queryFn: async () => {
      return await investmentService.getNavItemsForInvestments();
    },
  });
  const { isLoading, data } = useQuery({
    queryKey: ['message-count'],
    queryFn: async () => {
      return await secureMessageService.getMessageCount();
    },
  });

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [menuOpen]);

  return (
    <div className="w-full h-full bg-[#0b3755] md:hidden block">
      <div className="w-full h-full flex flex-row align-middle items-center pt-4 relative fixed top-0 right-0 z-[100]">
        <div className="h-full" onClick={() => drop.setDropDown(null)}>
          <Link to="/dashboard">
            <img src={logo} className="w-[280px] p-4" alt=""></img>
          </Link>
        </div>
        <div className="absolute right-5 cursor-pointer">
          <h4
            id="menu_text"
            className="text-white fade-in"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}>
            {menuOpen ? 'CLOSE' : 'MENU'}
          </h4>
        </div>
      </div>

      <div
        className={`${
          menuOpen ? 'visible' : 'hidden'
        } w-[100vw] h-[calc(100vh-70px)] fade-in relative z-[1000] overflow-y-scroll pb-36`}
        id="mobile_menu">
        <div className="w-[90%]">
          <hr className="border-1 border-midasLight mt-4 w-full ml-[5%]" />
          <div className="flex flex-col p-2 pl-8">
            <AccountViewSwitch />
          </div>
          <hr className="border-1 border-midasLight mt-2 w-full ml-[5%]" />


          <div className="flex flex-col gap-6 ml-10 mt-10">
            <div className={' font-semibold w-[100%] group'}>
              <div className={'flex flex-col gap-3'}>
                <div className={`flex flex-row justify-between gap-4`}>
                  <div className={'flex flex-row gap-2 align-middle justify-center items-center'}>
                    <DashboardLogo width={30} height={30} title="Secure messages"
                                   className={'group-hover:fill-sand fill-white'} />
                    <h6
                      className={'font-semibold text-lg select-none hover:cursor-pointer whitespace-nowrap text-white group-hover:text-sand'}
                      onClick={event => nav("/")}
                    >Dashboard</h6>
                  </div>
                </div>
              </div>
            </div>

            {/*portfolio */}
            <div className={'text-white font-semibold w-[100%]'}>
              <div className={'flex flex-col gap-3'}>
                <div className={`flex flex-row justify-between gap-4 group-hover:fill-sand group`}
                     onClick={event => {
                       const currentState = open.portfolio;
                       setOpen({ accountSettings: false, portfolio: !currentState });
                     }}
                >
                  <div className={'flex flex-row gap-2 align-middle justify-center items-center'}>
                    <PortfolioLogo width={30} height={30}
                                   className={`group-hover:fill-sand ${open.portfolio ? 'fill-sand' : 'fill-white'}`} />
                    <h6
                      className={`font-semibold select-none text-lg hover:cursor-pointer whitespace-nowrap group-hover:text-sand ${open.portfolio ? 'text-sand' : 'text-white'}`}>My
                      portfolio</h6>
                  </div>
                  <DropdownIcon title="Expand Section" width={30} height={30}
                                className={`float-right duration-500  group-hover:fill-sand ${open.portfolio ? 'rotate-180 fill-sand' : 'rotate-0 fill-white'}`} />
                </div>
                {navIsLoading && open.portfolio && <LoadingBlock />}

                <div className={`flex flex-col gap-2 text-left font-medium ${open.portfolio ? 'visible' : 'hidden'}`}>
                  {navData && navData.map((value, index) => {
                    return <NavDropDownLinks key={index} navItem={value} />;
                  })}

                </div>
              </div>
            </div>


            {/*Secure messages*/}
            <div className={' font-semibold w-[100%]  group'}>
              <div className={'flex flex-col gap-3'}>
                <div className={`flex flex-row justify-between gap-4`}>
                  <div className={'flex flex-row gap-2 align-middle justify-center items-center'}>
                    <SecMesLogo width={30} height={30} title="Secure messages"
                                className={'group-hover:fill-sand fill-white'} />
                    <h6
                      className={'font-semibold text-lg select-none hover:cursor-pointer whitespace-nowrap text-white group-hover:text-sand'}
                      onClick={event => nav("/inbox")}>Secure
                      messages</h6>
                    <div className="w-[25px] h-[25px] xl:block hidden">
                      <div
                        className={`rounded-full w-full h-full ml-1 -mt-4 bg-beret ${auth.token.as != undefined ? "hidden": "flex"}  align-middle items-center justify-center`} >
                        <p className="text-white text-[0.7rem] font-bold z-10 pt-[2px] cursor-default select-none">
                          {data && data > 99 ? '99+' : data?.toString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/*Account settings*/}
            <div className={'text-white font-semibold w-[100%]'}>
              <div className={'flex flex-col gap-3'} onClick={event => {
                const currentState = open.accountSettings;
                setOpen({ accountSettings: !currentState, portfolio: false });
              }}>
                <div className={`flex flex-row justify-between gap-4 group-hover:fill-sand group`}>
                  <div className={'flex flex-row gap-2 align-middle justify-center items-center'}>
                    <IconGear width={30} height={30}
                              className={`group-hover:fill-sand ${open.accountSettings ? 'fill-sand' : 'fill-white'}`} />
                    <h6
                      className={`font-semibold select-none text-lg hover:cursor-pointer whitespace-nowrap group-hover:text-sand ${open.accountSettings ? 'text-sand' : 'text-white'}`}>Account
                      settings</h6>
                  </div>
                  <DropdownIcon title="Expand Section" width={30} height={30}
                                className={`float-right duration-500  group-hover:fill-sand ${open.accountSettings ? 'rotate-180 fill-sand' : 'rotate-0 fill-white'}`} />
                </div>
                <div
                  className={`flex flex-col gap-2 text-left font-medium ${open.accountSettings ? 'visible' : 'hidden'}`}>
                  <Link to={'/update-contact-details'} className={'text-sm pl-10 '}>Contact details and preferences</Link>
                  <Link to={'/application-history'} className={'text-sm pl-10 '}>Application history</Link>
                  <Link to={'/commission-charges'} className={'text-sm pl-10'}>Commission and charges</Link>
                  <Link to={'/nominated-bank-account'} className={'text-sm pl-10'}>Nominated bank account</Link>
                  <Link to={'/rebate-bank-account'} className={'text-sm pl-10'}>VCT rebate bank account</Link>
                  <Link to={'/change-password'} className={'text-sm pl-10 '}>Password and security</Link>
                </div>
              </div>
            </div>
            <hr className="border-1 border-white mt-4 w-[100%]"></hr>
            <ReturnLink />
            <div className="mt-4">
              <LogoutComponent />
            </div>

          </div>


        </div>

      </div>
    </div>
  );
}

export default MobileMenu;