import React, {useState} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import LoginComponent from './pages/Login';
import AppLoginComponent from './pages/AppLogin';
import NewUserComponent from './pages/NewUser';
import DashboardPage from './pages/Dashboard';
import SecureMessages from './pages/SecureMessages/SecureMessages';
import ContactDetails from './pages/AccountSettings/UpdateContactDetails';
import PasswordPage from './pages/AccountSettings/ChangePassword';
import ChargesPage from './pages/AccountSettings/CommisionsCharges';
import ApplicationHistory from './pages/AccountSettings/ApplicationHistory';
import BankDetailsPage from './pages/AccountSettings/NominatedBankAccount';
import ProtectedRoute from './pages/Protected';
import NewMessage from './pages/SecureMessages/NewMessage';
import AdminSearch from './pages/AdminSearch';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import DependencyInject, {
    ServicesContext,
} from './context/DependencyInjector';
import InvestmentService from './services/InvestmentService';
import AuthenticationService from './services/Authentication';
import {AxiosInterceptor} from './services/api';
import {
    DropDownContext,
    DropDownContextType,
    DropDowns,
} from './context/DropDownContext';
import ClientService from './services/ClientService';
import FileUploadService from './services/FileUploadService';
import Reply from './pages/SecureMessages/Reply';
import {SecureMessageService} from './services/SecureMessageService';
import InvestmentDrillDown from './pages/InvestmentDetails/InvestmentDrillDown';
import {AdminService} from './services/AdminService';
import AdminProtectedRoute from './pages/AdminProtected';
import {TransactionService} from './services/TransactionService';
import {DocumentService} from './services/DocumentService';
import SettingsPage from './pages/AccountSettings/Settings';
import {PreferenceService} from './services/PreferenceService';
import ForgotPassword from './pages/ForgotPassword';
import PasswordResetConfirmation from './pages/PasswordResetConfirmation';
import MagicLogin from './pages/MagicLogin';
import {CommissionService} from './services/CommissionService';
import PortfolioOverview from './pages/Portfolio/PortfolioOverview';
import RebateBankAccount from './pages/AccountSettings/RebateBankAccount';
import {ISecurityService, SecurityService} from './services/SecurityService';
import {CtaService} from './services/CtaService';
import UnAuthedPreferences from './pages/UnAuthedPreferences';
import {UserService} from './services/UserService';
import {UnsubService} from './services/PrefServiceUnSub';

const router = createBrowserRouter(
    [
        {
            path: '/login',
            element: <LoginComponent/>,
        },
        {
            path: '/app_login',
            element: <AppLoginComponent/>,
        },
        {
            path: '/new_user',
            element: <NewUserComponent/>,
        },
        {
            path: '/login_token',
            element: <LoginComponent/>,
        },
        {
            path: '/auth/reset',
            element: <ForgotPassword/>,
        },
        {
            path: '/auth/magic',
            element: <MagicLogin/>,
        },

        {
            path: '/auth/conf',
            element: <PasswordResetConfirmation/>,
        },
        {
            path: '/prefs/:wcid',
            element: <UnAuthedPreferences/>,
        },
        {
            path: '',
            element: (
                <ProtectedRoute>
                    <DashboardPage/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/dashboard',
            element: (
                <ProtectedRoute>
                    <DashboardPage/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/inbox',
            element: (
                <ProtectedRoute>
                    <SecureMessages/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/dfm/:cat/:wrapper/:accountId',
            element: (
                <ProtectedRoute>
                    <InvestmentDrillDown/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/investment/:parent/:child',
            element: (
                <ProtectedRoute>
                    <InvestmentDrillDown/>
                </ProtectedRoute>
            ),
        },

        {
            path: '/investment/:parent',
            element: (
                <ProtectedRoute>
                    <PortfolioOverview/>
                </ProtectedRoute>
            ),
        },

        {
            path: '/update-contact-details',
            element: (
                <ProtectedRoute>
                    <ContactDetails/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/change-password',
            element: (
                <ProtectedRoute>
                    <PasswordPage/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/application-history',
            element: (
                <ProtectedRoute>
                    <ApplicationHistory/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/commission-charges',
            element: (
                <ProtectedRoute>
                    <ChargesPage/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/nominated-bank-account',
            element: (
                <ProtectedRoute>
                    <BankDetailsPage/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/rebate-bank-account',
            element: (
                <ProtectedRoute>
                    <RebateBankAccount/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/search',
            element: (
                <AdminProtectedRoute>
                    <AdminSearch/>
                </AdminProtectedRoute>
            ),
        },
        // {
        //   path: '/help-and-support',
        //   element: <HelpPage />,
        // },
        {
            path: '/new-message',
            element: (
                <ProtectedRoute>
                    <NewMessage/>
                </ProtectedRoute>
            ),
        },
        {
            path: '/reply/:id',
            element: (
                <ProtectedRoute>
                    <Reply/>
                </ProtectedRoute>
            ),
        },
        {
            path: 'settings',
            element: (
                <ProtectedRoute>
                    <SettingsPage/>
                </ProtectedRoute>
            ),
        },
    ],
    {basename: process.env.REACT_APP_BASE_ROUTER},
);

const queryClient = new QueryClient();

function App() {
    const injectableServices: ServicesContext = {
        investmentService: new InvestmentService(),
        authService: new AuthenticationService(),
        clientService: new ClientService(),
        adminService: new AdminService(),
        secureMessageService: new SecureMessageService(),
        fileUploadService: new FileUploadService(),
        transactionService: new TransactionService(),
        documentService: new DocumentService(),
        preferenceService: new PreferenceService(),
        commissionService: new CommissionService(),
        securityService: new SecurityService(),
        ctaService: new CtaService(),
        userService: new UserService(),
        unsubService: new UnsubService(),
    };

    const [dropDown, setDrop] = useState<DropDowns>(null);

    const setDropDown = (drop: DropDowns) => {
        setDrop(drop);
    };
    const dropDownContext: DropDownContextType = {
        setDropDown: setDropDown,
        openDropDown: dropDown,
    };

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <DependencyInject.Provider value={injectableServices}>
                    <AxiosInterceptor>
                        <DropDownContext.Provider value={dropDownContext}>
                            <RouterProvider router={router}/>
                        </DropDownContext.Provider>
                    </AxiosInterceptor>
                </DependencyInject.Provider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
