import React, { LabelHTMLAttributes } from 'react';
import Requirement from './Requirement';
import {
  validatePasswordHasLetter,
  validatePasswordHasNumeric,
  validatePasswordHasSpecialChar,
  validatePasswordIsLongEnough,
} from '../../utils/ValidationUtils';


export interface PasswordRequirementProps {
  password: string;
  htmlFor: LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
}

const PasswordRequirement = (props: PasswordRequirementProps) => {

  return (
    <div className={'grid grid-cols-1 w-full ml-2 text-tuxedo mt-2 text-sm'}>
        Your new password must include:
        <Requirement htmlFor={props.htmlFor} isValid={validatePasswordIsLongEnough(props.password)}
                     invalidMessage={'at least 8 characters'} validMessage={'at least 8 characters'} />

        <Requirement htmlFor={props.htmlFor} isValid={validatePasswordHasLetter(props.password)}
                   invalidMessage={'at least 1 letter'} validMessage={'at least 1 letter'} />

        <Requirement htmlFor={props.htmlFor} isValid={validatePasswordHasNumeric(props.password)}
                   invalidMessage={'at lease 1 number'} validMessage={'at lease 1 number'} />

        <Requirement htmlFor={props.htmlFor} isValid={validatePasswordHasSpecialChar(props.password)}
                   invalidMessage={'at lease 1 special character  e.g. (e.g. !@$%)'}
                   validMessage={'At least 1 special character  e.g. (e.g. !@$%)'} />

    </div>
  );

};


export default PasswordRequirement;

