import React from 'react';
import PageTitle from '../PageTitles/PageTitle';

import { NonCustodyHolding } from '../../dto/Holding';
import UnderlyingLoader from './SidePanelBlocks/investments/UnderlyingLoader';
import RelatedDocs from './SidePanelBlocks/docs/RelatedDocs';
import { useParams } from 'react-router-dom';

type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

const renderRelatedDocs = (holding: NonCustodyHolding) => {
  return (
    <div>
      <PageTitle
        title={'Important Documents'.toUpperCase()}
        subtitle={holding.investment.name}></PageTitle>

      <RelatedDocs holding={holding} />
    </div>
  );
};

const renderInvestmentDetails = (holding: NonCustodyHolding, data: NonCustodyHolding[]) => {
  const {parent, wrapper, accountId, cat } = useParams();

  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  return (
    <div className="mt-10">
      <PageTitle
        title={'Investment Details'.toUpperCase()}
        subtitle={accountId != undefined ? getTitleText() :holding.investment.name}></PageTitle>

      <UnderlyingLoader data={data} holding={holding} />
    </div>
  );
};

export const renderSideContent = (
  content: SideBarContent,
  data: NonCustodyHolding[] | undefined,
) => {
  if (content == null) return;

  if (content.openType == 'INVESTMENT') {
    return renderInvestmentDetails(content.holding, data!);
  } else {
    return renderRelatedDocs(content.holding);
  }
};
