export const validateAccountNumber = (accountNumber: string) => {
  const regex = new RegExp('^\\d{1,8}$');
  return regex.test(accountNumber);
};

export const validateSortCodeWithHyphen = (sortCode: string) => {
  const regex = new RegExp('^\\d{2}-\\d{2}-\\d{2}$');
  return regex.test(sortCode);
};


export const validatePasswordIsLongEnough = (password: string) => {
  return password.length >= 8;
};

export const validatePasswordHasSpecialChar = (password: string) => {
  return /[!@#$%^&*(),.?":{}|<>]/g.test(password)
}

export const validatePasswordHasNumeric = (password: string) => {
  const reg = new RegExp(".*\\d+.*")
  return  reg.test(password);
}

export const validatePasswordHasLetter = (password: string) => {
  const reg = new RegExp('[A-Za-z]')
  return  reg.test(password);

}