import React, { useState } from 'react';
import bgImage from '../images/login_backround.png';
import logo from '../images/wealth-club-portal-logo.svg';
import { useDependencyInjector } from '../context/DependencyInjector';
import { ClientSearch } from '../dto/ClientSearch';
import useToken from '../hooks/useToken';
import { useNavigate } from 'react-router-dom';
import { SpinnerCircularSplit } from 'spinners-react';
import LogoutComponent from '../components/Logout/Logout';
import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';

function AdminSearch() {
  const [searchValue, setSearchValue] = useState<string>('');
  const [results, setResults] = useState<Array<ClientSearch>>([]);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { adminService } = useDependencyInjector();
  const auth = useToken();
  const nav = useNavigate();

  const client = useQueryClient();

  const debouncedSearch = debounce((searchTerm) => {
    if (searchTerm != '' && searchTerm.length > 3) {
      setHasSearched(true);
      setLoading(true);
      setError(null);
      adminService
        .searchClients(searchTerm)
        .then((value) => {
          setResults(value);
          setError('');
        })
        .catch((reason) => {
          setError('Error loading clients please try again.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, 700);

  const setHeaderAndNav = (header: string, name: string) => {
    if (auth.token) {
      const cloneAuth = { ...auth.token, as: header, asName: name };
      auth.setToken(cloneAuth);
      client.invalidateQueries().then((value) => {
        nav('/');
      });
    }
  };

  return (
    <div className="w-[100vw] h-[100vh] text-right fade-in">
      <div
        className="flex flex-col items-center h-screen select-none"
        style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="w-[5vw] mt-[4vh] right-24 absolute">
          <LogoutComponent />
        </div>
        <img
          src={logo}
          className="md:w-[328px] w-[75%] h-auto m-12 md:mb-10 mt-[35vh]"
          alt=""></img>
        <div className="w-[90%] md:w-[40%] shadow-xl relative">
          <input
            className="w-full h-full focus:outline-none focus:rounded-lg rounded-none rounded-lg hover:rounded-l-lg hover:rounded-none  p-3"
            placeholder="Search for a client..."
            onChange={(event) => {
              setSearchValue(event.currentTarget.value);
              debouncedSearch(event.currentTarget.value);
            }}></input>

          <div className="absolute inset-y-0 right-0 pr-3 pt-2">
            {loading  && (
              <SpinnerCircularSplit
                color={'#AD9B5E'}
                size={30}
                thickness={175} />)}
          </div>
        </div>
        <div
          className={`rounded-md shadow-lg bg-white w-[90%] md:w-[40%] m-2 mt-3 flex flex-col p-4 ${
            hasSearched ? '' : 'hidden'
          }`}>
          <div className="w-full text-left mb-1">
            <h5 className="cursor-default">Search results</h5>
            {(searchValue.length > 3 && error) && (
              <p className={'p-4'} style={{ color: 'red' }}>
                {error}
              </p>
            )}
          </div>
          {results.length == 0 && (
            <p className="pl-4 float-left text-left mt-2 cursor-default">
              No clients found
            </p>
          )}
          {results.map((value, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setHeaderAndNav(value.id, value.fullName);
                }}
                className={
                  'w-full text-left cursor-pointer hover:rounded-md hover:bg-vistaWhite hover:shadow-lg pt-2 pb-2 flex align-middle mt-1 transition-all duration-150'
                }>
                <p className="pl-4">
                  {value.fullName}{' '}
                  {value.clientNum !== null && <span> -{value.clientNum}</span>}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AdminSearch;
