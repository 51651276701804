import React from 'react';
import { NonCustodyHolding } from '../../../../dto/Holding';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../../../context/DependencyInjector';
import DocTable from './DocTable';
import SectionHeader from '../../../PageSection/SectionHeader';
import ButtonComponent from '../../../Buttons/Button';
import ErrorBanner from '../../../Error/ErrorBanner';
import { ReactComponent as DownloadIcon } from '../../../../images/icons/icon-download.svg';
import FileDescriptor from '../../../../dto/FileDescriptor';
import fileDownload from 'js-file-download';
import HoldingWithDocuments from '../../../../dto/HoldingWithDocuments';
import { Button } from 'private-wc-ui';

interface DocProps {
  holding: NonCustodyHolding;
}

function RelatedDocs(props: DocProps) {
  const { investmentService, documentService } = useDependencyInjector();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['invest-docs-', props.holding.id],
    queryFn: async () => {
      return await investmentService.getDocumentsForInvestment(props.holding.id);
    },
  });


  const downloadAllFiles = async (holdingWithDocuments: HoldingWithDocuments) => {
    holdingWithDocuments.documents.map(async (value, index) => {
      const response = await documentService.getDocument(value.id!);
      fileDownload(response.media as Blob, response.fileName);
    });
  };


  return (
    <div className="mt-10 flex flex-col">
      {isError && !isLoading && (
        <ErrorBanner
          severe={true}
          text="Something went wrong. Please try again later"
        />
      )}

      {!isLoading && (
        data?.map((value) => {
          return (
            <div key={value.id} className={'m-4'}>
              <SectionHeader title={value.name} />
              <DocTable documents={value.documents} />
              <Button
                theme="secondary"
                label={
                  <span className={'flex flex-row align-bottom gap-2'}><DownloadIcon
                    className="h-[25px] fill-tuxedo" />
                                <p className={'mt-1 text-sm font-bold'}>Download All Files</p>
                              </span>}
                className={'mt-4'}
                style={{ fontWeight: 500 }}
                onClick={async () => {
                  await downloadAllFiles(value);
                }}
              >
              </Button>

            </div>
          );
        })
      )}

    </div>
  );
}

export default RelatedDocs;


