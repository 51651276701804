import React, { useEffect, useState } from 'react';
import ContentComponent from '../../components/ContentContainer/Content';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import { SecureMessage } from '../../dto/SecureMessage';
import PaginationButtons from '../../components/Pagination/Pagination';
import { capitalizeFirstLetter } from '../../utils/FormattingUtils';
import useWindowSize from '../../hooks/useWindowSize';
import ViewMessage from '../../components/Inbox/ViewMessage';
import TrendingOffersBox from '../../components/PageSection/TrendingOffersBox';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import MobileTable from '../../components/SecureMessages/MobileTable';
import DesktopTable from '../../components/SecureMessages/DesktopTable';
import FolderSwitcher from '../../components/SecureMessages/FolderSwitcher';
import ErrorBanner from '../../components/Error/ErrorBanner';
import useDeleteConfirmation from '../../components/Confirmation/useDeleteConfirmation';
import ConfirmationModal from '../../components/Confirmation/ConfirmationModal';
import useToken from '../../hooks/useToken';

function SecureMessages() {
  const nav = useNavigate();
  const { secureMessageService } = useDependencyInjector();
  const [folder, setFolder] = useState('inbox');
  const [pager, setPager] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [messages, setMessages] = useState<Array<SecureMessage>>([]);
  const { width } = useWindowSize();
  const [viewSecureMessage, setViewSecureMessage] = useState<SecureMessage>();
  const [hasAttachment, setHasAttachment] = useState<number>(0);
  const [sidebar, setSidebar] = useState<boolean>(false);

  const { requestConfirm, modalProps, handleClose } = useDeleteConfirmation();

  const auth = useToken()

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['messages', folder],
    queryFn: async () => {
      return await secureMessageService.getSecureMessages(folder);
    },
    enabled: auth.token.as === undefined
  });

  const handleSecureMessageNav = (secureMessage: SecureMessage) => {
    switch (secureMessage.folder) {
      case 20:
        if (secureMessage.draftReplyTo) {
          nav(`/reply/${secureMessage.draftReplyTo}?draftId=${secureMessage.id} `);
        } else {
          nav(`/new-message?draftId=${secureMessage.id}`);
        }
        break;
      default:
        setViewSecureMessage(secureMessage);
        scrollTo(0, 0);
        setSidebar(true);
        break;
    }
  };
  const setSecureMessage = (secureMessage: SecureMessage) => {
    setViewSecureMessage(secureMessage);
  };

  function updateSidebar(sidebarOpen: boolean) {
    setSidebar(sidebarOpen);
    if (!sidebarOpen) {
      document.body.style.overflowY = 'auto';
    }
  }

  const changeFolder = (folder: string) => {
    setFolder(folder);
    setPager(1);
    setMessages([]);
  };

  const updatePage = (page: number) => {
    setPager(page);
  };

  const deleteSecureMessage = async (secureMessageId: string) => {
    requestConfirm({
      description: 'Are you sure you want to delete this message?', onCancel: () => {
        handleClose();
      }, onConfirm: async () => {
        await secureMessageService.deleteSecureMessage(secureMessageId);
        await refetch();
        setSidebar(false);
        handleClose();
      },
    });
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const numberOfMessages = data.length;
      const MAX_PAGE_SIZE = 15; //number of messages per page
      let numberOfPagesRequired = Math.floor(numberOfMessages / MAX_PAGE_SIZE);
      if (numberOfMessages % MAX_PAGE_SIZE > 0) {
        numberOfPagesRequired += 1;
      }
      setNumberOfPages(numberOfPagesRequired);
      if (numberOfPagesRequired == 1) {
        setMessages(data);
      } else if (pager == numberOfPagesRequired) {
        const messages = data.slice(
          pager * MAX_PAGE_SIZE - MAX_PAGE_SIZE,
          data.length - 1,
        );
        setMessages(messages);
      } else {
        const messages = data.slice(
          pager * MAX_PAGE_SIZE - MAX_PAGE_SIZE,
          pager * MAX_PAGE_SIZE,
        );
        setMessages(messages);
      }
    }
  }, [data, pager, folder]);

  const renderTable = (messages: Array<SecureMessage>) => {
    if (width < 700) {
      return (
        <MobileTable
          deleteSecureMessage={deleteSecureMessage}
          folder={folder}
          handleSecureMessageNav={handleSecureMessageNav}
          messages={messages}
          page={pager}
        />
      );
    }
    return (
      <DesktopTable
        deleteSecureMessage={deleteSecureMessage}
        folder={folder}
        handleSecureMessageNav={handleSecureMessageNav}
        messages={messages}
        page={pager}
        setSidebar={setSidebar}
        setViewSecureMessage={setViewSecureMessage}
      />
    );
  };

  const getFakeMessages = (): SecureMessage[] => {
    const msgList = [] as SecureMessage[]
    for (let i = 0; i < 10; i++) {
      const msg = {
        id: "fake-message",
        createTs: "06/01/1999",
        createdBy: "wc",
        updateTs: "06/01/1999",
        subject: "fake-message",
        folder: "inbox",
        previousFolder: "inbox",
        sentTs: "06/01/1999",
        read: i % 2 === 0,
        fromClient: true,
        attachmentCount: 0,
        emailAlertWasSent: true,
        thread: "fake-message",
        threadParentId: "fake-message",
        reverseThreadParentId: "fake-message",
        body: "fake-message",
        draftReplyTo: "fake-message",
      } as SecureMessage
      msgList.push(msg)
    }
    return msgList
  }

  return (
    <div>
      {modalProps != null && <ConfirmationModal {...modalProps} />}
      {sidebar && (
        <ViewMessage
          refetch={async () => {
            await refetch();
          }}
          setSidebar={updateSidebar}
          sidebar={sidebar}
          secureMessage={viewSecureMessage}
          updateCurrentSecureMessage={setSecureMessage}
          hasAttachment={hasAttachment}
          deleteCurrentMessage={deleteSecureMessage}
          folder={folder}
        />
      )}
      <ContentComponent
        title="Secure messages"
        subtitle={capitalizeFirstLetter(folder)}
        actionBtn="new message"
        actionBtnLink="/new-message"
      >
        <div className="w-[100%] h-fit mb-12">
          <FolderSwitcher
            changeFolder={changeFolder}
            folder={folder}
          />
          <div className="w-full h-auto min-h-[40vh] mt-8">
            {messages && !isError && !isLoading && messages.length == 0 && (
              <ErrorBanner
                text={`You do not have any messages in your ${folder} folder`}
                severe={false}
              />
            )}
            {auth.token.as === undefined && isError && !isLoading && (
              <ErrorBanner
                text={`Error loading ${folder} folder. Please try again later.`}
                severe={true}
              />
            )}
            {isLoading && auth.token.as === undefined && (
              <div className="h-[62vh]">
                <LoadingBlock />
              </div>
            )}
            {auth.token.as != undefined &&
              <>
                <ErrorBanner
                  text={`Secure messages can only be viewed by the account holder`}
                  severe={false}
                />
                <div className={"blur-md"}>
                  {renderTable(getFakeMessages())}
                </div>
              </>
            }
            {messages && auth.token.as === undefined &&
              !isLoading &&
              messages.length > 0 &&
              renderTable(messages)
            }
            {auth.token.as === undefined && numberOfPages > 1 && messages && messages.length > 0 && (
              <PaginationButtons
                pageCount={numberOfPages}
                onPageChange={updatePage}
              />
            )}
          </div>
          <TrendingOffersBox />
        </div>
      </ContentComponent>
    </div>
  );
}

export default SecureMessages;