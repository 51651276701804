import React from 'react';
import { ReactComponent as AlphaSortDown } from '../../images/icons/icon-filter-a-z-down.svg';
import { ReactComponent as AlphaSortUp } from '../../images/icons/icon-filter-a-z-up.svg';
import { ReactComponent as SortDown } from '../../images/icons/icon-filter-down.svg';
import { ReactComponent as SortUp } from '../../images/icons/icon-filter-up.svg';

type SortValues = 'investment' | 'value' | 'growth' | null;

interface SortProps {
  setSort: (value: React.SetStateAction<SortValues>) => void;
  sort: SortValues;
  setDesc: (beDesc: boolean) => void;
  desc: boolean;
}

function TableSort(props: SortProps) {
  return (
    <div className="mt-10 mb-5">
      <div className="flex flex-row">
        <div
          className="flex flex-row duration-300"
          onClick={() => {
            props.setSort('investment');
            props.setDesc(!props.desc);
          }}>
          <h5
            className={`${
              props.sort === 'investment' ? 'text-bond' : 'font-normal'
            } text-sm cursor-pointer select-none`}>
            Investment
          </h5>
          {props.sort === 'investment' ? (
            props.desc ? (
              <AlphaSortDown className="h-5 cursor-pointer slow-fade-in duration-300" />
            ) : (
              <AlphaSortUp className="h-5 cursor-pointer slow-fade-in duration-300" />
            )
          ) : (
            <AlphaSortDown className="h-5 cursor-pointer slow-fade-in duration-300" />
          )}
        </div>
        <div className="ml-6">
          <div
            className="flex flex-row"
            onClick={() => {
              props.setSort('value');
              props.setDesc(!props.desc);
            }}>
            <h5
              className={`${
                props.sort === 'value' ? 'text-bond' : 'font-normal'
              } text-sm cursor-pointer select-none`}>
              Value
            </h5>
            {props.sort === 'value' ? (
              props.desc ? (
                <SortDown className="h-5 cursor-pointer slow-fade-in duration-300" />
              ) : (
                <SortUp className="h-5 cursor-pointer slow-fade-in duration-300" />
              )
            ) : (
              <SortDown className="h-5 cursor-pointer slow-fade-in duration-300" />
            )}
          </div>
        </div>
        <div className="ml-6">
          <div
            className="flex flex-row"
            onClick={() => {
              props.setSort('growth');
              props.setDesc(!props.desc);
            }}>
            <h5
              className={`${
                props.sort === 'growth' ? 'text-bond' : 'font-normal'
              } text-sm cursor-pointer select-none`}>
              Growth
            </h5>
            {props.sort === 'growth' ? (
              props.desc ? (
                <SortDown className="h-5 cursor-pointer slow-fade-in duration-300" />
              ) : (
                <SortUp className="h-5 cursor-pointer slow-fade-in duration-300" />
              )
            ) : (
              <SortDown className="h-5 cursor-pointer slow-fade-in duration-300" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableSort;